import UserInteractionEvents  from "./user_interaction_events";
import HeimrichHannotTrackingEvents from "./heimrich_hannot_tracking_events";

if (window.HeimrichHannotTrackingEvents === undefined) {
    window.HeimrichHannotTrackingEvents = HeimrichHannotTrackingEvents;
}

document.addEventListener('DOMContentLoaded', () => {
    if (window.InteractionEventsConfiguration !== undefined) {
        window.UserInteractionEvents = new UserInteractionEvents(window.InteractionEventsConfiguration);
    }
});