function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

function sanitizeValue(value) {
    return value.trim()
        // replace any amount of subsequent whitespace with a single space
        .replace(/\s+/g, ' ')
        // remove any characters that are not alphanumeric, whitespace, backslash, double quote,
        // single quote, underscore or hyphen, and remove trailing backslashes
        // .replace(/[^\wäöüß \\"'_-]|\\+$/ig, '')
        // escape all quotes that are not already escaped
        .replace(/(?<!\\)(["'])/g, '\\$1')
        .trim();
}

function sanitizeData(payload) {
    Object.keys(payload).forEach(key => {
        if (typeof payload[key] === 'string' && !isJsonString(payload[key])) {
            payload[key] = sanitizeValue(payload[key]);
        } else if (!payload[key]) {
            payload[key] = null;
        }
    });
    return payload;
}

class HeimrichHannotTrackingEvents
{
    static sanitizeData(payload) {
        return sanitizeData(payload);
    }

    static sanitizeValue(value) {
        return sanitizeValue(value);
    }

    /**
     * @param {Event} event
     * @param {Object} eventConfig
     * @returns {Object}
     */
    static navigation(event, eventConfig) {
        const nav = event.target.closest('nav');
        return sanitizeData({
            "item_name": event.target.innerText ?? '',
            "navbar_name": nav?.ariaLabel ?? nav?.id ?? ''
        });
    }

    static newsSelect(event, eventConfig) {
        return sanitizeData({
            "headline": event.currentTarget
                ?.querySelector('.headline, h1, h2, h3, h4, h5, h6, .card-title')
                ?.innerText
                ?? '',
        });
    }

    static pagination(event, eventConfig) {
        const active = event.target.closest('.pagination').querySelector('.active'),
            from_index = active.textContent,
            targetLi = event.target.closest('li');

        let to_index = event.target.textContent;
        if (targetLi.classList.contains('next')) {
            to_index = active.nextElementSibling?.textContent;
        } else if (targetLi.classList.contains('previous')) {
            to_index = active.previousElementSibling?.textContent;
        }

        return sanitizeData({
            "from_index": from_index,
            "goto_index": to_index
        });
    }

    static filter(event, eventConfig) {
        const form = event.target.closest("form"),
            nameRegex = new RegExp(`^${form.name}\\[([^\\]]*)](.*)$`, "i"),
            formData = new FormData(form),
            entries = Object.fromEntries(formData.entries()),
            filteredEntries = {};

        Object.keys(entries).forEach(key => {
            const newKey = key.replace(nameRegex, "$1$2"),
                input = form.querySelector(`[name="${key}"]`);

            if (input.tagName === "SELECT" && formData.get(key)) {
                filteredEntries[newKey] = input.querySelector(`option[value="${formData.get(key)}"]`)?.textContent;
            } else {
                filteredEntries[newKey] = input.value;
            }
        });

        return sanitizeData({
            "filter_json": JSON.stringify(filteredEntries),
            "category": form.getAttribute("aria-label")
                ?? form.getAttribute("aria-description")
                ?? form.name
                ?? '',
            "search_term": filteredEntries["freetext_search"]
                ?? form.querySelector('input.search-term, .search-term input')?.value
                ?? form.querySelector('input[type="search"]')?.value
                ?? form.querySelector('input[type="text"]')?.value
                ?? ''
        });
    }
}

export default HeimrichHannotTrackingEvents;