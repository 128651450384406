/**
 * @typedef {Object} Observable
 * @property {string} query
 * @property {string} on
 * @property {string} eventName
 */
/**
 * @typedef {Element} InteractiveElement
 * @property {{}} huhUserInteractionsBound
 */

class UserInteractionEvents {
    constructor(configuration) {
        this.configuration = configuration.config;
        this.events = configuration.events;
        this.observables = [];
        this.bindings = [];
        this.mutationObserver = this.createMutationObserver();

        Object.entries(this.configuration).forEach(([eventName, eventConfig]) => {
            Object.entries(eventConfig.jsEvents).forEach(([jsEventId, jsEventConfig]) => {
                const observable = this.addObservable(
                    jsEventConfig.cssSelector || '*',
                    jsEventConfig.jsEvent,
                    eventName
                );

                const initialElements = observable.query ? document.querySelectorAll(observable.query) : [];
                initialElements.forEach(element => {
                    this.bindListener(element, observable);
                });
            });
        });

        Object.entries(this.events).forEach(event => {
            (new Function(event))();
        });
    }

    createMutationObserver() {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                mutation.addedNodes.forEach(node => {
                    if (node.nodeType !== 1) {
                        return;
                    }
                    this.observables.forEach(observable => {
                        if (observable.query && node.matches(observable.query)) {
                            // console.log('matches', observable, node);
                            this.bindListener(node, observable);
                        }
                    });
                });

                // working draft: listen to attribute changes too
                if (mutation.type === 'attributes' && mutation.target.nodeType === 1) {
                    this.observables.forEach((observable) => {
                        if (observable.query && mutation.target.matches(observable.query)) {
                            // console.log('matches', observable, node);
                            this.bindListener(mutation.target, observable);
                        }
                    });
                }
            });
        });

        observer.observe(document.body, {
            attributes: true,
            childList: true,
            subtree: true
        });

        return observer;
    }

    /**
     * Create an observable and add it to the list of observables.
     *
     * @param {String} query - The query for the observable.
     * @param {String} on - The target object for the event listener.
     * @param {String} eventName - The name of the event to listen for.
     * @return {Observable} - The created observable.
     */
    addObservable(query, on, eventName) {
        const observable = {
            query: query,
            on: on,
            eventName: eventName
        };
        this.observables.push(observable);
        return observable;
    }

    /**
     * Binds a listener to the given element for the specified observable.
     *
     * @param {InteractiveElement|Node} element - The element to bind the listener to.
     * @param {Observable} observable - The observable to listen for events from.
     */
    bindListener(element, observable) {
        const { query, on, eventName } = observable;

        element.huhUserInteractionsBound = element.huhUserInteractionsBound || {};
        element.huhUserInteractionsBound[eventName] = element.huhUserInteractionsBound[eventName] || [];

        if (element.huhUserInteractionsBound[eventName].includes(on)) {
            return;
        }

        element.huhUserInteractionsBound[eventName].push(on);

        const callback = event => {
            if (element.matches(query)) {  // check if the registered element still matches the query
                this.evaluateEvent(eventName, event);
            }
        };

        element.addEventListener(on, callback);

        this.bindings.push({
            element: element,
            on: on,
            eventName: eventName,
            callback: callback
        });
    }

    evaluateEvent(eventName, event) {
        const eventConfig = this.configuration[eventName];

        if (!eventConfig || ((eventConfig.fireOnce ?? true) && eventConfig.used)) {
            return;
        }

        // event.preventDefault();
        let code  = eventConfig.code;
        let data = {};

        if (eventConfig.callback) {
            let parts = eventConfig.callback.split('.'),
                callback = !parts.length || (
                    parts.length === 1 ? window[parts[0]] : window[parts[0]]?.[parts[1]]
                );

            if (parts.length > 2 || typeof callback !== 'function') {
                console.error("Invalid callback function", eventName, eventConfig, parts, window[parts?.[0]], window[parts?.[0]]?.[parts?.[1]], callback);
                throw new Error('Invalid callback function');
            }

            data = callback(event, eventConfig);
            code = this.replaceTokens(code, data);
        }

        (new Function(code))();

        eventConfig.used = true;
    }

    replaceTokens(str, tokens) {
        if (!tokens) return;
        const tokenRegex = /##([^#]+)##/g;
        return str.replace(tokenRegex, (match, tokenName) => {
            // Check if the token exists in the tokens object
            if (tokens.hasOwnProperty(tokenName)) {
                return tokens[tokenName];
            }
            // If the token doesn't exist, leave it unchanged
            return match;
        });
    }
}

export default UserInteractionEvents;